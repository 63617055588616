import React, { useEffect } from 'react';

const Career = ({ redirectTo }) => {
  useEffect(() => {
    window.location.href = "https://main.dnulq6y2hppxo.amplifyapp.com/";
  }, [redirectTo]);

  return null; // Render nothing in this component
};

export default Career;
