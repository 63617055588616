import React, { useState } from "react";
import Property from "./Property/index";
import { Outlet } from "react-router-dom";
import StyleWrap from "../Home/style";
import { Grid } from "@mui/material";
import FilterAccordion from "./Filter/index";
// import { data } from "../Home/DataSet/index";
import { data } from "../../MasterData/DataSet";
import {
  setLastEvaluatedKey,
} from "../../Redux/globalState";
import styled from "styled-components";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useMedia } from "react-use";
import FilterListIcon from "@mui/icons-material/FilterList";

const VerticalDivider = styled.div`
  .divider {
    border-left: 1px solid #c4c1c18a;
    padding: 15px;
  }

  @media (max-width: 1200px) {
    .divider {
      border: none !important;
    }
  }
`;
const Home = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedPriceValue, setSelectedPriceValue] = useState("");
  const [show, setShow] = useState(false);
  const [totalData, setTotalData] = useState(data);

  const isLessThan1200 = useMedia("(max-width: 1200px)");

  return (
    <div>
      <StyleWrap>
        <Outlet />
        <Offcanvas
        className="me-5"
        style={{width: "250px", marginTop:'3.8rem',
        borderTopRightRadius: "10px",  // Border radius for top right corner
        // borderTopLeftRadius: "10px"  // Border radius for bottom right corner
    
      }}
        show={show}
        onHide={() =>setShow(false)}
      >
        <Offcanvas.Header closeButton>
            <Offcanvas.Title>Filter</Offcanvas.Title>
          </Offcanvas.Header>
        <Offcanvas.Body >
        <FilterAccordion
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              setTotalData={setTotalData}
              totalData={totalData}
              selectedPriceValue={selectedPriceValue}
              setSelectedPriceValue={setSelectedPriceValue}
              setLastEvaluatedKey={setLastEvaluatedKey}
            />
        </Offcanvas.Body>
      </Offcanvas> 

        <Grid container>
          {!isLessThan1200 && (
            <Grid item lg={3}>
              <section className="pe-3">
                <div className="filter_box">
                  <FilterAccordion
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                    setTotalData={setTotalData}
                    totalData={totalData}
                    selectedPriceValue={selectedPriceValue}
                    setSelectedPriceValue={setSelectedPriceValue}
                    setLastEvaluatedKey={setLastEvaluatedKey}
                  />
                </div>
              </section>
            </Grid>
          )}
          <Grid item lg={9}>
            <VerticalDivider>
              <div>
                {isLessThan1200 && (
                  <div
                    className="text_primary cursor-pointer fw-bold mb-4"
                    onClick={() => setShow(true)}
                  >
                    {" "}
                    <FilterListIcon /> Filter
                  </div>
                )}
                  <div
                    className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 
            place-content-stretch justify-items-center sm:justify-items-stretch"
                  >


                    {totalData?.sort((a,b)=>a.order - b.order).map( property =>{
                      return <Property key={property.id} property={property} />;
                    })}
                  </div>
              </div>
            </VerticalDivider>
          </Grid>
        </Grid>
      </StyleWrap>
    </div>
  );
};

export default Home;
