import Styled from "styled-components"

const StyleWrap = Styled.div`
.custom-btn {
  background-color: #453A91;
  color: white; 
  font-weight:600;
  border-color: #453A91;
  padding: 2px 13px;
  border-radius: 20px;
}
.custom-btn-btn {
  background-color: white;
  color: #453A91;
  font-weight:600;
  border-color: #453A91;
  padding: 2px 13px;
  border-radius: 20px;
}

.icon-container {
    display: flex;
    align-items: center;
  }
  
  .thumbs-up-icon {
    color: #94a426;
    margin-right: 10px; /* Adjust spacing between icons if needed */
  }
  
  .smile-icon {
    color: #94a426;
  }
  

/* modal.css */

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .modal-content {
    width: 400px; /* Fixed width */
    height: 200px; /* Fixed height */
    // background-color: #efeff5;
    padding: 20px; /* Adjust padding as needed */
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    
  }
  
  .close {
    position: absolute;
    top: 5px; /* Adjust the top position */
    right: 5px; /* Adjust the right position */
    cursor: pointer;
    font-size: 24px; /* Increase the font size */
    width: 30px; /* Increase the width */
    height: 30px; /* Increase the height */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%; /* Make it circular */
    background-color: rgba(0, 0, 0, 0.1); /* Add background color */
    color: rgba(0, 0, 0, 0.5); /* Change the color */
  }

  .center-content {
    display: flex;
    flex-direction: column; /* Arrange items in a column */
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensure content is centered vertically */
  }
  

.custom-btn:hover {
  background-color: #0F0074;
  border-color: rgb(235, 128, 10); 
}
.custom-btn-btn:hover {
  background-color: #0F0074;
  color: white;
  border-color: #0F0074;
}
.offcanvas-body{
    padding: 30px !important;

}
.test_1{
margin: 0px !important;
}
.image_count{
    z-index: 9;
    position: relative;
    background: white;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #D6D6D6;
    border-radius: 26px;
    padding: 18px;
    color: #C6C7C7 !important;
}
.count{
    // padding: 5px;
    background: #c7c6c652;
    border-radius: 50%;
    width: 29px;
    height: 29px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.divider{
    padding: 0 10px;
}
.filter_box{
     border: 1px solid #00000024;
    border-radius: 15px;
    // box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}
.icons{
    width: 27px;
    height: 27px;
    display: inline-flex;
}
.enquiry-card{
    margin-bottom: 50px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.details_block{
    display: flex;
}
.MuiFormHelperText-root.Mui-error {
    font-family: "Montserrat", sans-serif !important;
}
.error{
    color: #d32f2f;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}
.enquiry_footer{
    font-size: 12px;
}
.MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-family: "Montserrat", sans-serif !important;
}
@media screen and (max-width: 640px) {
    .button-block{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .details_block{
        display: block;
    }

   
}
`

export default StyleWrap
