
// Product 1 Real Estate
import realEstateBuyerImage from "../Assets/Images/realEstateBuyerImage.png";
import realEstateSellerImage from "../Assets/Images/realEstateSellerImage.jpg";
import realEstatePartnerImage from "../Assets/Images/realEstatePartnerImage.png";


// Product 2 Education 
import tutorImage from "../Assets/Images/tutorImage.png";
import studentImage from "../Assets/Images/studentImage.png";
import partnerImage from "../Assets/Images/partnerImage.png";

import liveAttendanceEmployer from "../Assets/Images/liveAttendanceEmployer.png";
import userCheckInImage from "../Assets/Images/userCheckInImage.png";

// Others 
import enquiryImage from "../Assets/Images/enquiryImage.png";
import compliantImage from "../Assets/Images/compliantImage.png";
import feedbackImage from "../Assets/Images/feedbackImage.png";

// productStatus - In Development, Live, InActive

export const data = 
    [
        // Real Estate - Seller
        {
            "propertyid": 3450,
            "productName" : "RealEstate",
            "productCode" : "N01SellerRE",
            "label": "For Seller",
            "searchBy": "Real Estate For Seller",
            "category": "Real Estate",
            "systemUrl": "https://realestateapp.newebon.com/register",
            "order": 1,
            "bed": "2",
            "productStatus" : "Live",
            "releaseDate" : "29-Mar-2024",


            "suburb": {
                "suburbId": "22",
                "features": [
                    "The estate is close access to modern services including hospitals, shopping centres, public transportation, schools, and major thoroughfares is only a bonus.",
                    "",
                    "3km to Armadale Station and Armadale Town CentrE",
                    "Less than 40km to Perth CBD",
                    "Less than 35km to Rockingham",
                    "Breathtaking scenic views",
                    "Everything right on your doorstep",
                    "For investors, the average 3-bedroom home achieves a return of around $370 per week with a 4-bedroom generating an average of $420 per week."
                ],
                "postcode": "6112",
                "suburb": "For Seller",
                "description": "You can find affordable lots in Brookdale, rectangular blocks that are simple to deal with, and breathtaking views of the Darling Scarp's bushland. ",
                "location": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13509.506062293794!2d115.99167750763945!3d-32.16712016878827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32930f6f0d5a91%3A0x504f0b535df2530!2sBrookdale%20WA%206112%2C%20Australia!5e0!3m2!1sen!2sin!4v1702292411897!5m2!1sen!2sin",
                "growth": "17.8%",
                "state": "WA"
            },
            "landsize": "1990",
            "bath": "7",
            "estimatedweeklyrental": "hello this is sample content hello this is sample content hello this is sample content hello this is sample content hello this is sample content",
            "content": "Ready to sell your property? Trust our expertise to help you achieve your goals",
            "state": "",
            "listed": "2024-03-17T02:07:50.942Z",
            "packageprice": "1121212",
            "garage": "4+",
            "imageurl": [
                realEstateSellerImage
            ]
        },

        // Real Estate - Buyer
        {
            "propertyid": 3434,
            "productName" : "RealEstate",
            "productCode" : "N01BuyerRE",
            "label": "For Buyer",
            "systemUrl": "https://realestate.newebon.com/",

            "order": 2,
            "bed": "3",
            "productStatus" : "Live",
            "releaseDate" : "29-Mar-2024",

            "suburb": {
                "suburbId": "49",
                "features": [
                    "16 km from the Perth CBD",
                    "Midland is only a 5-minute drive away",
                    "Close to the airport",
                    "Take advantage of the 185ha of conservation bushland",
                    "5 mins from historic Guildford"
                ],
                "postcode": "6055",
                "suburb": "For Buyer",
                "description": "Bushmead offers attractive property options for first home buyers, and subsequent home buyers alike. Amenities within the Bushmead community make it an ideal choice for young families looking for sustainable property options only 16km from the Perth CBD.",
                "location": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27090.38490690085!2d116.00921564504308!3d-31.925662763774834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32b885863cb9d1%3A0xc64652c743674494!2sBushmead%20WA%206055%2C%20Australia!5e0!3m2!1sen!2sin!4v1702292341936!5m2!1sen!2sin",
                "growth": "4.2%",
                "state": "CA"
            },
            "landsize": "455",
            "Registration": "NotRegistered",
            "bath": "3",
            "estimatedweeklyrental": "747",
            "content": "Ready to take the next step towards finding your dream home?.",

            "state": "",
            "listed": "2024-03-15T09:27:59.499Z",
            "packageprice": "747",
            "category": "Real Estate",
            "garage": "2",
            "imageurl": [
                realEstateBuyerImage
            ]
        },
        
        // Real Estate - Partner

        {
            "propertyid": 3433,
            "productName" : "RealEstate",
            "productCode" : "N01PartnerRE",
            "label": "For Partner",
            "systemUrl": "https://main.d3qlbd2zb3ezqu.amplifyapp.com/",

            "order": 3,
            "bed": "3",
            "productStatus" : "Live",
            "releaseDate" : "29-Mar-2024",

            "suburb": {
                "suburbId": "58",
                "features": [
                    "The long term vision for Banksia Grove",
                    "Western Australia’s best Master-Planned Development Community",
                    "Carefully designed to create a sustainable environment for its residents",
                    "Winner of the WA Waterwise Land Development Award"
                ],
                "postcode": "6031",
                "suburb": "For Partner",
                "description": "With almost 3700 homes set around beautiful parklands with a great selection of schools, shopping centres, and transport links in easy reach, Banksia Grove is a winning example of a land estate that has grown into a thriving, well-connected community.",
                "location": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27156.389706843413!2d115.78597764438314!3d-31.70090288148024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2bcd524d6b95c50d%3A0x504f0b535df2570!2sBanksia%20Grove%20WA%206031%2C%20Australia!5e0!3m2!1sen!2sin!4v1702295039906!5m2!1sen!2sin",
                "growth": "10.2%",
                "state": "CA"
            },
            "landsize": "565",
            "Registration": "Registered",
            "bath": "2",
            "estimatedweeklyrental": "6565",
            "content": "Let's work together to elevate the real estate experience for our clients",

            "state": "",
            "listed": "2024-03-15T09:27:46.923Z",
            "packageprice": "656456",
            "category": "Real Estate",
            "garage": "3",
            "imageurl": [
                realEstatePartnerImage
            ]
        },

       

        // Live attendance - Employer
        // {
        //     "propertyid": 3445,
        //     "productName" : "Attendance",
        //     "productCode" : "N03AT",
        //     "productCode" : "N03EmployerLA",
        //     "label": "For Employer",
        //     "systemUrl": "https://liveattendance.newebon.com/",

        //     "order": 7,
        //     "bed": "2",
        //     "productStatus" : "Live",
        //     "releaseDate" : "29-Mar-2024",

        //     "suburb": {
        //         "suburbId": "2",
        //         "features": [
        //             "Live in the luxuriant natural setting of Piara Waters. Explore the Piara Nature Reserve's parks and gardens or the neighbouring wetlands to see thousands of native birds, bandicoots, and frogs. In Piara Waters, there is something for everyone. All ages can play on playgrounds, and there are also pump tracks, boardwalks, running trails, a multi-use court, exercise nodes, and much more.",
        //             "",
        //             "Forrestdale South East Primary School, St. John Bosco College, Aspiri Primary School, Harrisdale High School, Carey Baptist College, and Thornlie Christian College are just a few of the schools that are close by.",
        //             "21 kms to Perth CBD",
        //             "27 kms to Perth airport",
        //             "Close to major shopping centres and sporting complexes",
        //             "Active lifestyle with walking and running trails through native bushland",
        //             "Selection of great educational facilities on your doorstep",
        //             "For investors, the average 3-bedroom home achieves a return of around $487 per week with a 4-bedroom generating an average of $550 per week."
        //         ],
        //         "postcode": "6112",
        //         "suburb": "For Employer",
        //         "description": "Piara Waters is a rapidly developing neighbourhood that is ideally situated 21 kilometres south-east of the Perth CBD and offers quick access to a wide variety of well-established services and facilities. Nearby amenities include major shopping centres, a train station, bus depots, sports venues, and freeway access.",
        //         "location": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27029.532317115263!2d115.89549559565108!3d-32.13163173977129!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a3296e998c65f41%3A0x504f0b535e16540!2sPiara%20Waters%20WA%206112%2C%20Australia!5e0!3m2!1sen!2sin!4v1702292694849!5m2!1sen!2sin",
        //         "growth": "15.1%",
        //         "state": "TX"
        //     },
        //     "landsize": "111",
        //     "Registration": "Registered",
        //     "bath": "5",
        //     "estimatedweeklyrental": "111",
        //     "content": "Real-time attendance tracking made simple, and effortless attendance monitoring in real time.",
        //     "state": "",
        //     "listed": "2024-03-15T12:23:09.281Z",
        //     "packageprice": "111",
        //     "category": "Live Attendance",
        //     "garage": "2",
        //     "imageurl": [
        //         liveAttendanceEmployer
        //     ]
        // },

        // Live attendance - Worker
        // {
        //     "propertyid": 3439,
        //     "productName" : "Attendance",
        //     "productCode" : "N03WorkerLA",
        //     "systemUrl": "https://liveattendance.newebon.com/",

        //     "order": 8,
        //     "productStatus" : "Live",
        //     "releaseDate" : "29-Mar-2024",

        //     "suburb": {
        //         "suburbId": "9",
        //         "features": [
        //             "Golden Bays newest premium beachside stage:",
        //             "Adjoins Secret Harbour and all the amenities that it has to offer",
        //             "Located a mere 500m from the beach and the much loved Shipwreck Cove is on its doorstep",
        //             "Features elevated blocks, some offering glimpses of the ocean",
        //             "Includes a range of blocks to suit a range of lifestyles"
        //         ],
        //         "postcode": "6174",
        //         "suburb": "For Worker",
        //         "description": "If sea breezes, beach walks and Indian Ocean sunsets are your thing, you won't get much closer to the real thing in Golden Bay's newest precinct, Sunset Rise. Here you will have a choice of premium coastal blocks, that are perfect for your dream home.",
        //         "location": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13471.078573992194!2d115.74993555783058!3d-32.4253280186657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a3287524fa1cb03%3A0x504f0b535df4510!2sGolden%20Bay%20WA%206174%2C%20Australia!5e0!3m2!1sen!2sin!4v1702293252910!5m2!1sen!2sin",
        //         "growth": "21.3%",
        //         "state": "WA"
        //     },
        //     "landsize": "6554",
        //     "Registration": "NotRegistered",
        //     "bath": "4",
        //     "estimatedweeklyrental": "6765",
        //     "content": "Experience the simplicity of real-time attendance tracking and effortless check-ins with our user-friendly app.",
        //     "bed": "1",
        //     "state": "",
        //     "listed": "2024-03-15T09:44:40.110Z",
        //     "packageprice": "7676",
        //     "category": "Live Attendance",
        //     "garage": "3",
        //     "imageurl": [
        //         userCheckInImage
        //     ]
        // },
       

       // People - Enquiry
       {
        "propertyid": 3439,
        "productName" : "Enquiry",
        "productCode" : "N10UserEN",
        "order": 9,
        "systemUrl": "https://main.d3qlbd2zb3ezqu.amplifyapp.com/",
        "productStatus" : "Live",
        "releaseDate" : "26-Mar-2024",

        "suburb": {
            "suburbId": "9",
            "features": [
                "Golden Bays newest premium beachside stage:",
                "Adjoins Secret Harbour and all the amenities that it has to offer",
                "Located a mere 500m from the beach and the much loved Shipwreck Cove is on its doorstep",
                "Features elevated blocks, some offering glimpses of the ocean",
                "Includes a range of blocks to suit a range of lifestyles"
            ],
            "postcode": "6174",
            "suburb": "For People",
            "description": "If sea breezes, beach walks and Indian Ocean sunsets are your thing, you won't get much closer to the real thing in Golden Bay's newest precinct, Sunset Rise. Here you will have a choice of premium coastal blocks, that are perfect for your dream home.",
            "location": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13471.078573992194!2d115.74993555783058!3d-32.4253280186657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a3287524fa1cb03%3A0x504f0b535df4510!2sGolden%20Bay%20WA%206174%2C%20Australia!5e0!3m2!1sen!2sin!4v1702293252910!5m2!1sen!2sin",
            "growth": "21.3%",
            "state": "WA"
        },
        "landsize": "6554",
        "Registration": "NotRegistered",
        "bath": "4",
        "estimatedweeklyrental": "6765",
        "content": "Effortlessly submit your inquiries and receive prompt responses with our streamlined system.",
        "bed": "1",
        "state": "",
        "listed": "2024-03-15T09:44:40.110Z",
        "packageprice": "7676",
        "category": "Enquiry",
        "garage": "3",
        "imageurl": [
            enquiryImage
        ]
    },
   
     // People - Compliant
    //  {
    //     "propertyid": 3439,
    //     "productName" : "Compliant",
    //     "productCode" : "N10UserCP",
    //     "order": 10,
    //     "systemUrl": "https://main.d2xewwuyes8w03.amplifyapp.com/",
    //     "productStatus" : "InActive",
    //     "releaseDate" : "26-Mar-2024",

    //     "suburb": {
    //         "suburbId": "9",
    //         "features": [
    //             "Golden Bays newest premium beachside stage:",
    //             "Adjoins Secret Harbour and all the amenities that it has to offer",
    //             "Located a mere 500m from the beach and the much loved Shipwreck Cove is on its doorstep",
    //             "Features elevated blocks, some offering glimpses of the ocean",
    //             "Includes a range of blocks to suit a range of lifestyles"
    //         ],
    //         "postcode": "6174",
    //         "suburb": "For People",
    //         "description": "If sea breezes, beach walks and Indian Ocean sunsets are your thing, you won't get much closer to the real thing in Golden Bay's newest precinct, Sunset Rise. Here you will have a choice of premium coastal blocks, that are perfect for your dream home.",
    //         "location": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13471.078573992194!2d115.74993555783058!3d-32.4253280186657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a3287524fa1cb03%3A0x504f0b535df4510!2sGolden%20Bay%20WA%206174%2C%20Australia!5e0!3m2!1sen!2sin!4v1702293252910!5m2!1sen!2sin",
    //         "growth": "21.3%",
    //         "state": "WA"
    //     },
    //     "landsize": "6554",
    //     "Registration": "NotRegistered",
    //     "bath": "4",
    //     "estimatedweeklyrental": "6765",
    //     "content": "We're here to address your complaints and concerns promptly and efficiently, ensuring your satisfaction.",
    //     "bed": "1",
    //     "state": "",
    //     "listed": "2024-03-15T09:44:40.110Z",
    //     "packageprice": "7676",
    //     "category": "Compliant",
    //     "garage": "3",
    //     "imageurl": [
    //         compliantImage
    //     ]
    // },
   
     // People - Feedback
     {
        "propertyid": 3439,
        "productName" : "Feedback",
        "productCode" : "N10UserFB",
        "order": 11,
        "systemUrl": "https://main.d2kni6qeg7ozve.amplifyapp.com/",
        "productStatus" : "Live",
        "releaseDate" : "26-Mar-2024",

        "suburb": {
            "suburbId": "9",
            "features": [
                "Golden Bays newest premium beachside stage:",
                "Adjoins Secret Harbour and all the amenities that it has to offer",
                "Located a mere 500m from the beach and the much loved Shipwreck Cove is on its doorstep",
                "Features elevated blocks, some offering glimpses of the ocean",
                "Includes a range of blocks to suit a range of lifestyles"
            ],
            "postcode": "6174",
            "suburb": "For People",
            "description": "If sea breezes, beach walks and Indian Ocean sunsets are your thing, you won't get much closer to the real thing in Golden Bay's newest precinct, Sunset Rise. Here you will have a choice of premium coastal blocks, that are perfect for your dream home.",
            "location": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13471.078573992194!2d115.74993555783058!3d-32.4253280186657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a3287524fa1cb03%3A0x504f0b535df4510!2sGolden%20Bay%20WA%206174%2C%20Australia!5e0!3m2!1sen!2sin!4v1702293252910!5m2!1sen!2sin",
            "growth": "21.3%",
            "state": "WA"
        },
        "landsize": "6554",
        "Registration": "NotRegistered",
        "bath": "4",
        "estimatedweeklyrental": "6765",
        "content": "We value your input! Please take a moment to share your feedback and help us enhance your experience.",
        "bed": "1",
        "state": "",
        "listed": "2024-03-15T09:44:40.110Z",
        "packageprice": "7676",
        "category": "Feedback",
        "garage": "3",
        "imageurl": [
            feedbackImage
        ]
    },
   
    // https://career.newebon.com/
       
        
    ];
