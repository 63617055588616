import { Route, Routes, Navigate } from "react-router-dom";
import Home from "../Pages/Home/index";
import Career from "../Pages/Career";
import Enquiry from "../Pages/Enquiry";

import { useProperty } from "../Components/Layout/UseLayout/useProperty";
import Main from "../Components/Layout/Main";

const Routers = () => {
  const { DetailedPropertyComponent } = useProperty();

  return (
    <Routes>
      <Route element={<Main />}>
        <Route path="/" element={<Home />} />
        <Route
          path="/career"
          element={
            <Career to="https://main.dnulq6y2hppxo.amplifyapp.com/" replace />
          }
        />
        <Route
          path="/enquiry"
          element={
            <Enquiry to="https://main.d1umq7yq5vd61o.amplifyapp.com/" replace />
          }
        />
        <Route path="/property/:id" element={<DetailedPropertyComponent />} />
        {/* Catch all undefined routes and redirect to the home page */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
};
export default Routers;
