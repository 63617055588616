import { AppBar, Container } from "@mui/material";
// import logoIcon1 from "../../../Assets/Images/logo1.svg";
import logoIcon from "../../../Assets/Images/logo.svg";
import React from "react";
import UIButton from "../../Reusable/UIButton";
import { Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Styled from "styled-components";
import { Height } from "@mui/icons-material";

const DasboardWrap = Styled.div`


@media screen and (min-width: 1200px) {
  padding: 0 10%;
}
`;

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleLogoClick = () => {
    if (pathname === "/") {
      // refresh the page
      window.location.reload();
    }
    navigate("/");
  };

  return (
    <AppBar
      sx={{
        paddingY: "4px",
        paddingX: "0px",
        background: "white",
        position: "sticky",
        top: "0",
      }}
      id="property-header"
    >
      <DasboardWrap>
        <div className="flex justify-between items-center px-4 py-3">
          <div
            className="relative cursor-pointer pr-1"
            onClick={handleLogoClick}
            // style={{ height: '40px' }} // Set a fixed height for the container

          >
            {/* <img
              alt="logo"
              src={logoIcon1}
              className="object-cover min-h-[20px] max-h-[40px]"
            /> */}

            <img
              alt="logo"
              src={logoIcon}
              // className="logo_image"
              style={{ height: "35px", width: "auto" }}

              // className="object-cover min-h-[20px] max-h-[40px]"
              // className="object-cover min-h-[40px] max-h-[80px]"
            />
            {/* <div className="font-semibold text-base absolute text_primary bottom-0 right-0 translate-y-3 -translate-x-4">
              props
            </div> */}
          </div>

          {/* <div className="pl-1 flex flex-wrap items-center justify-end gap-1 md:gap-3 translate-y-1">
            <div className="flex justify-end">
              {" "}
              <Button
                sx={{
                  textTransform: "none",
                  ...(window.innerWidth < 450 && { fontSize: "10px" }),
                  color: "#FEA001",
                }}
                onClick={() =>
                  (window.location.href =
                    "https://user-portal.d1galasmgstb41.amplifyapp.com/login")
                }
                variant="text"
              >
                Login
              </Button>
              <span className="mx-1" />
              <UIButton
                onClick={() =>
                  (window.location.href =
                    "https://user-portal.d1galasmgstb41.amplifyapp.com/register")
                }
              >
                Register
              </UIButton>
            </div>
          </div>
           */}
        </div>
      </DasboardWrap>
    </AppBar>
  );
};

export default Header;
