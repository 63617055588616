import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import React, { useState } from "react";
import StyleWrap from "../style";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setViewProperty } from "../../../Redux/globalState";
// import logoIcon1 from "../../../Assets/Images/logo1.svg";
import logoIcon from "../../../Assets/Images/logo.svg";

const Property = ({ property }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const [hovering, setHovering] = React.useState(false);
  const [releaseDate, setReleaseDate] = useState(null); // State for releaseDate

  const handlePropertyClick = (e, url, productStatus, releaseDate) => {
    // setShowModal(true);
    if (productStatus === "InDevelopment") {
      // setShowModal(true);
      setShowModal({ show: true });
      setReleaseDate(releaseDate); // Set releaseDate
    } else {
      // navigate(url);
      window.open(url, "_blank");
      // dispatch(setViewProperty(property));
    }
  };
  const handleLink = (e, url, productStatus, releaseDate) => {
    if (productStatus === "InDevelopment") {
      // setShowModal(true);
      setShowModal({ show: true });
      setReleaseDate(releaseDate); // Set releaseDate

    } else {
      e.stopPropagation();
      window.open(url, "_blank");
    }
  };
  return (
    <StyleWrap>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>
              &times;
            </span>
            <div className="center-content">
            {/* <img
                alt="logo"
                src={logoIcon1}
                className="object-cover min-h-[20px] max-h-[40px]"
              /> */}

              <img
                alt="logo"
                src={logoIcon}
                className="object-cover min-h-[40px] max-h-[40px]"
              />

              <p style={{ marginTop: "20px" }}>Release Date : {releaseDate}</p>

              <div className="icon-container">
                <i className="fas fa-thumbs-up fa-2x thumbs-up-icon"></i>{" "}
                <i className="far fa-smile fa-2x smile-icon"></i>{" "}
              </div>
            </div>
          </div>
        </div>
      )}

      <Card
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        className="cursor-pointer h-full w-full"
        // onClick={(e)=>{handlePropertyClick(e, `/property/${property?.propertyid}`)}}
        onClick={(e) => {
          handlePropertyClick(
            e,
            property?.systemUrl,
            property?.productStatus,
            property?.releaseDate
          );
        }}
      >
        <CardMedia
          component="img"
          alt={property?.propertyid}
          className={`object-cover ${
            hovering ? "scale-105 -translate-y-1" : "scale-100"
          } min-h-[176px] h-44 w-full transition-all`}
          image={property?.imageurl[0]}
        />
        <CardContent sx={{ padding: ".4rem", marginBottom: "4px" }}>
          <Typography
            variant="h6"
            component="div"
            className="flex justify-between items-center"
          >
            <div>{property?.suburb?.suburb}</div>

            <div className="text-base">{property?.state} </div>
          </Typography>

          <div className="font-light text-sm pb-2">{property?.category}</div>

          <div className="pt-2 text-sm">
            {/* Estimated Weekly Rent:{" "} */}
            {property?.content}
          </div>
          <div className="d-flex flex-column gap-2 mt-3 justify-content-evenly">
            <Button
              className="custom-btn"
              onClick={(e) => {
                handleLink(
                  e,
                  property?.systemUrl,
                  property?.productStatus,
                  property?.releaseDate
                );
              }}
            >
              Contact
            </Button>
            <Button
              className="custom-btn-btn"
              onClick={(e) => {
                handleLink(
                  e,
                  property?.systemUrl,
                  property?.productStatus,
                  property?.releaseDate
                );
              }}
            >
              View More
            </Button>
          </div>
        </CardContent>
      </Card>
    </StyleWrap>
  );
};

export default Property;
