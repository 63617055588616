import React, { useEffect } from 'react';

const Enquiry = ({ redirectTo }) => {
  useEffect(() => {
    window.location.href = "https://main.d1umq7yq5vd61o.amplifyapp.com/";
  }, [redirectTo]);

  return null; // Render nothing in this component
};

export default Enquiry;
