import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { primaryColor } from "../../../Constant/index";
import { useSelector, useDispatch } from "react-redux";
import { setProperty } from "../../../Redux/globalState";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Divider from "@mui/material/Divider";
import { setFiltered } from "../../../Redux/globalState";
import { data } from "../../../MasterData/DataSet";


const MUIAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: "none",
  padding: "0px 5px",
  margin: "0px",
  "&.MuiAccordion-root": {
    "&::before": {
      height: 0,
    },
  },
}));

const blueColorText = "primaryColor";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: primaryColor,
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: primaryColor,
  },
});

function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}
export default function AccordionExpandDefault({
  selectedValue,
  setSelectedValue,
  setTotalData,
  totalData,
  filterByLandSize,
  filterByPrice,
  fetchData,
  setPriceFilter,
  setAreaFilter,
}) {
  const [selectedBathOptions, setSelectedBathOptions] = useState([]);
  const [selectedStateOptions, setSelectedStateOptions] = useState([]);
  const dispatch = useDispatch();

  const tempProperty = useSelector((state) => state.globalState.tempProperty);
  // const filtered = useSelector((state) => state.globalState.filtered);
  // const filteredData = useSelector((state) => state.globalState.filteredData);

  useEffect(() => {
    if (selectedValue.length === 0) {
      dispatch(setProperty(tempProperty));
      dispatch(setFiltered(false));
    }
  }, [selectedValue]);

  const handleCheckChange = (value) => {
    const index = selectedBathOptions.indexOf(value);
    let updatedBathOptions = [];
    if (index === -1) {
      updatedBathOptions = [...selectedBathOptions, value];
    } else {
      updatedBathOptions = selectedBathOptions.filter(
        (option) => option !== value
      );
    }
    setSelectedBathOptions(updatedBathOptions);
    filterData(updatedBathOptions, selectedStateOptions);
  };

  const filterData = (bathFilters, stateFilters) => {
    if (bathFilters.length === 0 && stateFilters.length === 0) {
      setTotalData(data);
      return;
    }
    let filteredData = [...data];
    if (bathFilters.length > 0) {
      filteredData = filteredData.filter((item) =>
        bathFilters.includes(item.productName)
      );
    }
    if (stateFilters.length > 0) {
      filteredData = filteredData.filter((item) =>
        stateFilters.includes(item.productCode)
      );
    }
    setTotalData(filteredData);
  };

  const handleStateCheckboxChange = (value) => {
    const index = selectedStateOptions.indexOf(value);
    let updatedStateOptions = [];

    if (index === -1) {
      updatedStateOptions = [...selectedStateOptions, value];
    } else {
      updatedStateOptions = selectedStateOptions.filter(
        (option) => option !== value
      );
    }

    setSelectedStateOptions(updatedStateOptions);
    filterData(selectedBathOptions, updatedStateOptions);
  };

  const productOptions = [
    { value: "RealEstate", label: "Real Estate" },
    // { value: "Education", label: "Online Study" },
    // { value: "Attendance", label: "Live Attendance" },
    // { value: "FurnitureShop", label: "Furniture Shop" },
  ];

  const RealEstateSubProductOptions = [
    { value: "N01SellerRE", label: "Seller" },
    { value: "N01BuyerRE", label: "Buyer" },
    { value: "N01PartnerRE", label: "Partner" },
  ];

  // const EducationSubProductOptions = [
  //   { value: "N01TeacherED", label: "Teacher" },
  //   { value: "N01StudentED", label: "Student" },
  //   { value: "N01PartnerED", label: "Partner" },
  // ];

  const LiveAttendanceSubProductOptions = [
    { value: "N03EmployerLA", label: "Employer" },
    { value: "N03WorkerLA", label: "Worker" },
  ];

  const GeneralSubProductOptions = [
    { value: "N10UserEN", label: "Enquiry" },
    // { value: "N10UserCP", label: "Compliant" },
    { value: "N10UserFB", label: "Feedback" },

  ];


  const [bathTaskOpen, setBathTaskOpen] = useState(true);
  const [statefilterOpen, setStatefilterOpen] = useState(true);
  const [onlineStudyFilterOpen, setOnlineStudyFilterOpen] = useState(true);
  const [liveAttendanceFilterOpen, setLiveAttendanceFilterOpen] = useState(true);
  const [supportFilterOpen, setSupportFilterOpen] = useState(true);

  const handleStateAccordToggle = () => {
    setStatefilterOpen((prev) => !prev);
  };
  const handlebathAccordToggle = () => {
    setBathTaskOpen((prev) => !prev);
  };
  
  const handleOnlineStudyAccordToggle = () => {
    setOnlineStudyFilterOpen((prev) => !prev);
  };

  const handleLiveAttendanceAccordToggle = () => {
    setLiveAttendanceFilterOpen((prev) => !prev);
  };

  const handleSupportAndFeedbackAccordToggle = () => {
    setSupportFilterOpen((prev) => !prev);
  };


  return (
    <>
      <MUIAccordion expanded={bathTaskOpen} onChange={handlebathAccordToggle}>
        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{ border: "none" }}
        >
          <Typography color={blueColorText}>All Products</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {productOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <BpCheckbox
                    onChange={() => {
                      handleCheckChange(option.value);
                    }}
                  />
                }
                label={
                  <span dangerouslySetInnerHTML={{ __html: option.label }} />
                }
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </MUIAccordion>
      <Divider />

      <MUIAccordion
        expanded={statefilterOpen}
        onChange={handleStateAccordToggle}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{ border: "none" }}
        >
          <Typography color={blueColorText}>Real Estate</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {RealEstateSubProductOptions?.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <BpCheckbox
                    onChange={() => {
                      handleStateCheckboxChange(option.value);
                    }}
                  />
                }
                label={
                  <span dangerouslySetInnerHTML={{ __html: option.label }} />
                }
              />
            ))}
          </FormGroup>
        </AccordionDetails>
        </MUIAccordion>
        <Divider />

        {/* Education Filter  Hold */}
        {/* <MUIAccordion
        expanded={onlineStudyFilterOpen}
        onChange={handleOnlineStudyAccordToggle}
      >

        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{ border: "none" }}
        >
          <Typography color={blueColorText}>Online Study</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {EducationSubProductOptions?.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <BpCheckbox
                    onChange={() => {
                      handleStateCheckboxChange(option.value);
                    }}
                  />
                }
                label={
                  <span dangerouslySetInnerHTML={{ __html: option.label }} />
                }
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </MUIAccordion>
       */}
      <Divider />

         {/* Live Attendance  */}

         {/* <MUIAccordion
        expanded={liveAttendanceFilterOpen}
        onChange={handleLiveAttendanceAccordToggle}
      >

        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{ border: "none" }}
        >
          <Typography color={blueColorText}> Live Attendance </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {LiveAttendanceSubProductOptions?.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <BpCheckbox
                    onChange={() => {
                      handleStateCheckboxChange(option.value);
                    }}
                  />
                }
                label={
                  <span dangerouslySetInnerHTML={{ __html: option.label }} />
                }
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </MUIAccordion> */}


         {/* Support & Feedback  */}

         <MUIAccordion
        expanded={supportFilterOpen}
        onChange={handleSupportAndFeedbackAccordToggle}
      >

        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{ border: "none" }}
        >
          <Typography color={blueColorText}> Support & Feedback </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {GeneralSubProductOptions?.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <BpCheckbox
                    onChange={() => {
                      handleStateCheckboxChange(option.value);
                    }}
                  />
                }
                label={
                  <span dangerouslySetInnerHTML={{ __html: option.label }} />
                }
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </MUIAccordion>

    </>
  );
}
