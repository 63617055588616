import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

const initialState = {
  alertMessage: "",
  showEnquiryModal: false,
  packagePercent: [0, 100],
  LastEvaluatedKey: 0,
  property: [],
  tempProperty: [],
  filtered: false,
  filteredData: [],
  propertyFilter: {
    // propertName: "",
    location: "",
    noOfBedrooms: "",
    category: "",
    packagePrice: {
      min: 0,
      max: 10000000,
    },
  },
  confirmationModal: {
    open: false,
    size: "440px",
    header: "",
    footer: "",
    vPosition: "center",
    message: "",
    type: "",
  },
  viewProperty: []
}

export const globalState = createSlice({
  name: "globalState",
  initialState,
  reducers: {
    setAlertMessage: (state, action) => {
      state.alertMessage = action.payload
      toast(action.payload.message, {
        type: action.payload.type,
        position: action.payload.position || "top-center",
      })
    },
    setShowEnquiryModal: (state, action) => {
      state.showEnquiryModal = action.payload
    },
    setPropertyFilter: (state, action) => {
      state.propertyFilter = action.payload
    },
    setProperty: (state, action) => {
      state.property = action.payload
    },
    setTempProperty: (state, action) => {
      state.tempProperty = action.payload
    },
    setPackagePercent: (state, action) => {
      state.packagePercent = action.payload
    },
    setConfirmationModal: (state, action) => {
      state.confirmationModal = action.payload
    },
    setLastEvaluatedKey: (state, action) => {
      state.LastEvaluatedKey = action.payload
    },
    setFiltered: (state, action) => {
      state.filtered = action.payload
    },
    setFilteredData: (state, action) => {
      state.filteredData = action.payload
    },
    setViewProperty: (state, action) => {
      state.viewProperty = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setAlertMessage,
  setPropertyFilter,
  setConfirmationModal,
  setPackagePercent,
  setShowEnquiryModal,
  setLastEvaluatedKey,
  setTempProperty,
  setProperty,
  setFiltered,
  setFilteredData,
  setViewProperty
} = globalState.actions

export default globalState.reducer
